// change the theme
$theme-colors: (
  'primary': #2f80ed,
  'danger': #bf222d,
);
$body-color: #1f343d;
$input-color: #1e2432;
$input-plaintext-color: #1e2432;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
